<template>
	<div class="p-1">
      <div>
        <a-button @click="$router.back()" class="mb-4">
          Regresar
        </a-button>
      </div>
        <div class="flex justify-between flex-wrap items-center mb-2">
            <h3 class="text-2xl">Pagos realizados</h3>
        </div>

        <a-table
            :columns="columns"
            :source="pagination.data"
            :loading="loading"
            toolbar-column-filter
            @toolbar(refresh)="search({ page: 1 })"
            @toolbar(search)="search({ search: $event, page: 1 })">
            <template #toolbar>
                <v-select
                    class="w-56"
                    @input="search({ status: $event, page: 1 })"
                    :clearable="false"
                    v-model="query.status"
                    :options="[
                        { label: 'Pendiente por revisar', value: '1' },
                        { label: 'Aprobado', value: '2' },
                        { label: 'Rechazado', value: '3' },
                        { label: 'Todos', value: null }
                    ]"
                    :reduce="$0 => $0.value"/>
            </template>
        </a-table>
        <a-laravel-pagination align="right" :data="pagination" @change="search({ page: $event })" class="mt-3"/>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    data: () => ({
        query: {
            limit: 10,
            with: 'student,period',
            search: '',
            status: '1',
            sort_by: 'date,desc'
        },
        timeout: null
    }),
    computed: {
        ...mapState({
            pagination: state => state.payments.pagination,
            loading: state => state.payments.loading
        }),
        columns() {
            return [
                { title: 'Fecha', key: 'date' },
                { title: 'Factura', key: 'receipt_number' },
                { title: 'Monto', key: 'amount', mutate: ({ key }) => `${'$' + parseFloat((key / 100)).toFixed(2)}`, },
                { title: 'Tipo', key: 'type' },
                { title: 'Estado', key: 'status' },
                { title: 'Estudiante', key: 'student', mutate: ({ key }) => `${key.first_name} ${key.last_name}` },
                { title: 'Período', key: 'period', mutate: ({ key }) => `${key.year}-${key.number}` },
            ]
        }
    },
    methods: {
        handleStatusChange(status) {
            if (status) {
                this.$router.replace({ query: this.$repositoryUtils.withoutInvalidValues({...this.$route.query, status }) })
                  .catch(() => null)
            } else {
                let query = this.$route.query
                delete query.status
                delete this.query.status
                this.$router.replace({ query: this.$repositoryUtils.withoutInvalidValues(query) })
                  .catch(() => null)
            }

            this.fetchPayments(this.$repositoryUtils.withoutInvalidValues({ ...this.query, page: this.$route.query.page || 1 }))
        },
        ...mapActions({
            fetchPayments: 'payments/fetchAll',
            deletePayment: 'payments/delete',
            changePaymentStatus: 'payments/changePaymentStatus'
       }),
        getTypeString(type) {
            switch (type) {
                case 1: return "Efectivo"
                case 2: return "100% Banco"
                case 3: return "Transferencia Bancaria"
                case 4: return "Transferencia de Curso"
                case 5: return "Zelle"
                default: "Tipo no definido"
            }
        },
        getStatusString(state) {
            switch (state) {
                case 1: return "Pendiente por revisar"
                case 2: return "Aprobado"
                case 3: return "Rechazado"
                default: return "Estado no definido"
            }
        },
        search(query) {
            this.fetchPayments(this.$repositoryUtils.withoutInvalidValues({ ...this.query, ...query, student: this.$route.params.id }))
        }
   },
   mounted() {
       this.search({ ...this.query, page: this.$route.query.page || 1 })
   },
   created() {
        this.query = {
            ...this.query,
            ...this.$route.query
        }
   }
}
</script>
